/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* width */
::-webkit-scrollbar {
    scrollbar-width: thin;
    width: 10px;
    height: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    padding: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: darkgrey;
}


.loading_spinner {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10000;
    background-color: rgba(255, 255, 255, 0.7);
}

.flex {
    display: flex;
    column-gap: 10px;
}

.flex.rows {
    flex-direction: row;
    align-items: center;
}

.flex.rows.align-flex-start {
    align-items: flex-start !important;
}

.flex.columns {
    flex-direction: column;
    row-gap: 10px;
}

.flex.columns.align-flex-start {
    align-items: flex-start !important;
}

.flex.rows>*,
.flex.columns>* {
    flex: 1;
}